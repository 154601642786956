import React from "react";
import CompanyDetails from "./CompanyDetails";
import Form from "./Form";

export default function Contact() {
  return (
    <section id="contact">
      <Form />
      <CompanyDetails />
    </section>
  );
}
