import img1 from "../assets/about/card1.jpg";
import img2 from "../assets/about/card2.jpg";
import img3 from "../assets/about/card3.jpg";
import img4 from "../assets/about/card4.jpg";
import img5 from "../assets/about/card5.jpg";
import img6 from "../assets/about/card6.jpg";

const data = {
  title: "What we do?",
  paras: [
    {
      id: 1,
      imgSrc: img1,
      altText: "",
      alignment: "left",
      text: "In modern agriculture success depends on the ability to manage resources, maximise yield while minimising costs. Recent developments in Cloud computing and IoT devices, it has now become possible to gather valuable data and generate predictive models on vital farm metrics that enable to farmer to automate most of the regular farming tasks.",
    },
    {
      id: 2,
      imgSrc: img2,
      altText: "",
      alignment: "right",
      text: "At WakeupFarms, we strongly believe that Natural farming and Technology can compliment each other to provide environmentally sustainable and high yielding farming models. These models provide the farmers with technology to automate regular farming activities like watering, fertilizing pest control, etc while also providing crop patterns, seed selections and high yielding methods of sustainable agriculture.",
    },
    {
      id: 3,
      imgSrc: img3,
      altText: "",
      alignment: "left",
      text: "We also provide farmers with natural methods of preparing their own fertilizers, pesticides, and other farm inputs right on the farm which reduces substantial input costs. This reduction in costs with increased in quality yield has been observed to provide up-to 5 times higher returns for the farmer.",
    },
    {
      id: 4,
      imgSrc: img4,
      altText: "",
      alignment: "right",
      text: "WakeupFarms, provides end to end solution comprising of IoT devices powered by LoRaWAN, monitoring and automation of farming tasks powered by AWS Cloud services. Farmers can monitor all the metrics on their dashboard or mobile app and manually act or let our Machine Learning models to predict the thresholds and automate farming tasks.",
    },
    {
      id: 5,
      imgSrc: img5,
      altText: "",
      alignment: "left",
      text: "Major challenge in providing technology enabled solutions in remote farms is the ability to provide connectivity and availability of power. LoRaWAN provides long distance-low power connectivity that enables our solution to span our monitoring methods over large areas and remote locations with even mobile data connectivity.",
    },
    {
      id: 6,
      imgSrc: img6,
      altText: "",
      alignment: "right",
      text: "WakeupFarms solutions are powered by AWS cloud platform which provides world class security and scalability. So weather your farm is 1 acre or 100 acres, our solutions scales seamlessly and provide you with the insights and automation to improve yield and reduce costs.",
    },
  ],
};

export default data;
