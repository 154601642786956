import { Box, Image } from "@theme-ui/components";
import React from "react";
import Carousel from "react-responsive-carousel/lib/js/components/Carousel/index";

export default function Carousal(props) {
  return (
    <Carousel {...props}>
      {props.bannerImages.map((image, i) => (
        <>
          {props.variant === "bannerImages" && (
            <Box as="div" variant="styles.headerTransparency" />
          )}
          <Image key={i} src={image} variant={props.variant} alt="" />
        </>
      ))}
    </Carousel>
  );
}
