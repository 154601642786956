/** @jsxImportSource theme-ui */
import React, { useEffect } from "react";
import { Container, Flex, Text, Link as Tlink, Image } from "theme-ui";
import { Link, scroller } from "react-scroll";
import Logo from "../../assets/logo.png";
import menuItems from "../../data/header.data";
import { DrawerProvider } from "../../contexts/drawer/drawer.provider";
import MobileDrawer from "./MobileDrawer";

export default function Header({ className }) {
  useEffect(() => {
    scroller.scrollTo("about");
  }, []);
  return (
    <DrawerProvider>
      <header sx={styles.header} className={className} id="header">
        <Container sx={styles.container}>
          <Tlink href="/" variant="logo" sx={styles.logoContainer}>
            <Image src={Logo} variant="headerLogo" alt="" />
            <Text as="div" variant="logo">
              Wakeupfarms Agritech LLP
            </Text>
          </Tlink>
          <Flex as="nav" sx={styles.nav}>
            {menuItems.map(({ path, label }, i) => (
              <Link
                activeClass="active"
                to={path}
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                key={i}
                sx={{ fontFamily: "link" }}
                onSetActive={() => {
                  window.location.hash = (label === "Home" ? "" : path) + "_";
                }}
              >
                {label}
              </Link>
            ))}
          </Flex>
          <MobileDrawer />
        </Container>
      </header>
    </DrawerProvider>
  );
}

const styles = {
  header: {
    color: "text_secondary",
    fontWeight: "body",
    py: 1,
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    transition: "all 0.4s ease",
    "&.sticky": {
      position: "fixed",
      backgroundColor: "background",
      color: "text",
      boxShadow: "0 1px 2px rgba(0, 0, 0, 0.06)",
      py: 2,
    },
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  logoContainer: {
    display: "flex",
    cursor: "pointer",
  },
  nav: {
    display: ["none", null, null, "flex"],
    a: {
      fontSize: 2,
      fontWeight: "body",
      px: 5,
      cursor: "pointer",
      lineHeight: "1.2",
      transition: "all 0.15s",
      "&:hover": {
        color: "accent",
      },
      "&.active": {
        color: "accent",
      },
    },
    flex: 1,
    justifyContent: "flex-end",
  },
};
