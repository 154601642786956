import React, { useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { Link, Text } from "theme-ui";

export const CropData = () => {
  const [columnDefs, setColumnDefs] = useState([]);
  const [rowData, setRowData] = useState([]);
  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      wrapHeaderText: true,
      initialWidth: 300,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      headerClass: "text-center",
      cellClass: "text-center",
    };
  }, []);

  const createColDefObject = ({ headerName, field }) => {
    return {
      headerName: headerName,
      field: field,
      cellRendererSelector: (params) => {
        if (
          typeof params?.value === "string" &&
          params?.value?.startsWith("http")
        ) {
          return {
            component: () => (
              <Link href={params?.value} variant="default" target={"_blank"}>
                <Text>{params.value}</Text>
              </Link>
            ),
          };
        }
        return undefined;
      },
    };
  };

  useEffect(() => {
    fetch("/api/cropData")
      .then((res) => res.json())
      .then((res) => {
        if (res?.data?.Items?.length > 0) {
          const firstRow = res.data.Items[0];
          const columns = [];
          const columnNames = Object.keys(firstRow).sort();
          columnNames.forEach((field) => {
            if (firstRow[field].constructor === Object) {
              const parentColumn = {
                headerName: field,
                marryChildren: true,
                children: [],
              };
              Object.keys(firstRow[field]).forEach((childField) => {
                parentColumn.children.push(
                  createColDefObject({
                    headerName: childField,
                    field: `${field}.${childField}`,
                  })
                );
              });
            } else {
              columns.push(
                createColDefObject({
                  headerName: field,
                  field: field,
                })
              );
            }
          });
          setColumnDefs(columns);
          setRowData(res.data.Items);
        }
      })
      .catch((e) => {});
  }, []);

  return (
    <div style={styles.root}>
      <div style={styles.headerContainer}>
        <Text sx={styles.header}>Crop Data</Text>
      </div>
      <div className="ag-theme-alpine" style={styles.gridContainer}>
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
        ></AgGridReact>
      </div>
    </div>
  );
};

const styles = {
  root: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  header: {
    fontSize: [3, null, null, 5],
    fontWeight: "normal",
    color: "text",
  },
  gridContainer: { height: "90vh", width: "90vw", marginBottom: 20 },
};
