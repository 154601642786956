import React from "react";
import { toast } from "react-toastify";

export default function Toast(props) {
  const message = props.message ? props.message : "";
  const options = {
    position: props.position ? props.position : "bottom-center",
    autoClose: props.autoClose ? props.autoClose : 5000,
    hideProgressBar: props.hideProgressBar ? props.hideProgressBar : false,
    closeOnClick: props.closeOnClick ? props.closeOnClick : true,
    pauseOnHover: props.pauseOnHover ? props.pauseOnHover : true,
    draggable: props.draggable ? props.draggable : true,
    progress: props.progress ? props.progress : undefined,
  };

  if (props.variant === "success") return toast.success(message, options);
  else if (props.variant === "warning") return toast.warning(message, options);
  else if (props.variant === "error") return toast.error(message, options);
}
