/** @jsxImportSource theme-ui */
import { Container, Grid, Box, Heading, Text } from "theme-ui";
import SectionHeader from "../../components/reusable/SectionHeader";
import data from "../../data/workflow.data";
import WheatBG from "../../assets/background/wheat.png";
import arrowOdd from "../../assets/about/arrowOdd.svg";
import arrowEven from "../../assets/about/arrowEven.svg";
import Zoom from "react-reveal/Zoom";

export default function WorkFlow() {
  return (
    <section id="workflow" sx={styles.workflow}>
      <Container>
        <Box as="div" variant="styles.imageOpacity" />

        <Zoom>
          <SectionHeader
            slogan=""
            title="Let’s see how it works"
            isWhite={true}
          />

          <Grid sx={styles.grid}>
            {data.map(item => (
              <Box sx={styles.card} key={item.id}>
                <Box sx={styles.iconBox}>{`0${item.id}`}</Box>
                <Box sx={styles.wrapper}>
                  <Heading sx={styles.wrapper.title}>{item.title}</Heading>
                  {/* <Text sx={styles.wrapper.subTitle}>{item.text}</Text> */}
                </Box>
              </Box>
            ))}
          </Grid>
        </Zoom>
      </Container>
    </section>
  );
}

const styles = {
  workflow: {
    backgroundColor: "accent",
    backgroundImage: `url(${WheatBG})`,
    backgroundRepeat: `no-repeat`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    position: "relative",
    py: [8, null, 9, null, null, 10],
  },
  grid: {
    mb: -1,
    pt: 0,
    gridGap: [
      "35px 0",
      null,
      "45px 30px",
      null,
      "50px 25px",
      null,
      null,
      "50px 65px",
    ],
    gridTemplateColumns: [
      "repeat(1,1fr)",
      null,
      "repeat(2,1fr)",
      null,
      "repeat(4,1fr)",
    ],
  },
  card: {
    display: "flex",
    alignItems: "center",
    marginLeft: 5,
    flexDirection: "column",
    position: "relative",
    textAlign: ["center", null, "left"],
    width: ["100%", "80%", "100%"],
    mx: ["auto"],
    px: [4, null, null, 0],
    "&::before": {
      position: "absolute",
      content: '""',
      top: 0,
      left: [0, null, null, null, null, 170, null, 190],
      backgroundRepeat: `no-repeat`,
      backgroundPosition: "center center",
      width: 215,
      height: 60,
      "@media screen and (max-width:1220px)": {
        display: "none",
      },
    },
    "&:nth-of-type(2n-1)::before": {
      backgroundImage: `url(${arrowOdd})`,
    },
    "&:nth-of-type(2n)::before": {
      backgroundImage: `url(${arrowEven})`,
      top: 17,
    },
    "&:last-child::before": {
      display: "none",
    },
  },

  iconBox: {
    width: ["50px", null, "60px", null, null, "70px"],
    height: ["50px", null, "60px", null, null, "70px"],
    flexShrink: 0,
    borderRadius: [15, null, 23, null, null, 30],
    backgroundColor: "background",
    display: "flex",
    alignItems: "center",
    mb: [5, null, null, null, null, 6],
    mx: ["auto", null, 0],
    fontSize: [6, null, 7, null, null, "30px"],
    fontWeight: 700,
    justifyContent: "center",
    color: "heading",
  },
  wrapper: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    mt: "-5px",
    title: {
      fontSize: [3, null, 4, null, null, 5],
      color: "accent",
      lineHeight: [1.4, null, null, null, null, 1.55],
      pr: [0, null, null, null, null, 2],
      mb: [2, 3],
      textAlign: "center",
    },

    subTitle: {
      fontSize: 1,
      fontWeight: 400,
      lineHeight: [1.85, null, null, 1.9, 2],
      color: "text_secondary",
      // opacity: 0.75,
      pr: [0, null, null, null, null, 5],
    },
  },
};
