const theme = {
  colors: {
    text: "#02073E", // body color and primary color
    text_secondary: "#fcfcfc", // secondary body color
    text_muted: "#888",
    heading: "#0F2137", // primary heading color
    heading_secondary: "#0F2137", // heading color
    background: "#FFFFFF", // body background color
    background_secondary: "#000", // secondary background color
    border_color: "#E5ECF4", // border color
    primary: "#EA3A60", // primary button and link color
    secondary: "#2563FF", // secondary color - can be used for hover states
    muted: "#E4E4E4", // muted color
    accent: "#FFA740", // a contrast color for emphasizing UI
    button_light: "#BBC7D7",
    success: "#4B7B0A",
    warning: "orange",
    error: "tomato",
    input_border: "#ccc",
    input_background: "#fffded",
  },

  fonts: {
    body: "Purisa",
    heading: "Purisa", //"SFProReg",
    logo: "Purisa",
    input: "Purisa",
    text: "Purisa",
    link: "Purisa",
  },
  fontSizes: [12, 15, 16, 18, 20, 22, 24, 28, 32, 36, 42, 48, 52, 64],
  fontWeights: {
    body: "normal",
    heading: 500,
    bold: 700,
  },
  space: [0, 5, 10, 15, 20, 25, 30, 50, 80, 100, 120, 150],
  lineHeights: {
    body: 1.8,
    heading: 1.5,
  },
  letterSpacings: {
    body: "normal",
    caps: "0.2em",
    heading: "-0.5px",
  },
  images: {
    headerLogo: {
      width: 60,
      height: 60,
    },
    bannerImages: {
      width: "100%",
      height: ["100vw", "60vw", "45vw"],
      // height: ["100vw", "50vw", "60vw", "35vw"],
      filter: "brightness(100%)",
    },
  },
  breakpoints: [
    "480px",
    "640px",
    "768px",
    "1024px",
    "1220px",
    "1366px",
    "1620px",
  ],
  layout: {
    container: {
      maxWidth: [
        "100%",
        null,
        null,
        "780px",
        "1020px",
        "1200px",
        null,
        "1310px",
      ],
      px: [4, 6],
    },
  },
  section: {
    keyPoint: {
      pt: [8, null, 9, null, null, 8],
      pb: ["90px", null, 9, null, null, 7],
    },
    point: {
      pb: [8, null, 9, null, null, 10, null, "170px"],
    },
    services: {
      pb: ["90px", null, 9, null, null, 6, null, "150px"],
    },
    testimonial: {
      pt: [8, null, 8, "70px", null, 7, null, "145px"],
      overflow: "hidden",
    },
  },

  sectionHeader: {
    width: ["100%", null, "540px"],
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    mt: ["-3px", null, -1],
    marginBottom: ["40px"],
    mx: "auto",
    title: {
      fontSize: ["24px", null, "28px", null, null, "32px", null, "36px"],
      color: "heading",
      lineHeight: [1.3, null, null, 1.25],
      textAlign: "center",
      fontWeight: "700",
      letterSpacing: "-.5px",
    },

    subTitle: {
      fontSize: [0, "13px", null, "14px"],
      color: "accent",
      textAlign: "center",
      letterSpacing: ["1.5px", null, "2px"],
      textTransform: "uppercase",
      fontWeight: "700",
      mb: 2,
      lineHeight: 1.5,
    },
  },
  links: {
    default: {
      cursor: "pointer",
    },
    bold: {
      fontWeight: "bold",
    },
    nav: {
      display: ["none", null, "inline-block"],
      px: 25,
      fontWeight: "normal",
    },
    logo: {
      textDecoration: "none",
    },
  },
  buttons: {
    primary: {
      variant: "buttons.defaultBtn",
      color: "background",
      bg: "accent",
      "&:hover": {
        boxShadow: "accent 0px 9px 20px -5px",
      },
    },
  },
  text: {
    default: {
      fontSize: 1,
      fontFamily: "text",
    },
    logo: {
      fontSize: [4, 5, 6, 7],
      fontWeight: "bold",
      fontFamily: "logo",
      alignSelf: "center",
      pl: "10px",
      color: "accent",
    },
    heading: {
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      color: "text",
    },
    description: { pt: ["10px"] },
  },
  styles: {
    headerTransparency: {
      width: "100%",
      height: "70px",
      opacity: 0.5,
      backgroundColor: "background_secondary",
      position: "absolute",
      left: 0,
      top: 0,
      zIndex: 10,
    },
    imageOpacity: {
      width: "100%",
      height: "100%",
      opacity: 0.9,
      backgroundColor: "background_secondary",
      position: "absolute",
      left: 0,
      top: 0,
      zIndex: 0,
    },
  },
};

export default theme;
