/** @jsxImportSource theme-ui */
import React from "react";
import { Container, Box, Text, Image } from "theme-ui";
import SectionHeader from "../../components/reusable/SectionHeader";
import data from "../../data/aboutUs.data";
import Zoom from "react-reveal/Zoom";

export default function AboutUs() {
  return (
    <section id="aboutUs" sx={{ variant: "section.services" }}>
      <Container sx={styles.containerBox}>
        <Box sx={styles.contentBox}>
          <SectionHeader title={data.title} />

          {data.paras.map(item => (
            <Box sx={styles.box} key={item.id}>
              <Box
                sx={{
                  ...styles.imgContainer,
                  float: [
                    null,
                    null,
                    null,
                    item.alignment === "left" ? "right" : "left",
                  ],
                }}
              >
                <Zoom>
                  <Image
                    src={item.imgSrc}
                    alt={item.altText}
                    sx={{
                      ...styles.img,
                      float: [
                        null,
                        null,
                        null,
                        item.alignment === "left" ? "right" : "left",
                      ],
                    }}
                  />
                </Zoom>
              </Box>
              <Box
                sx={{
                  ...styles.para,
                  float: [null, null, null, item.alignment],
                  paddingLeft: [
                    null,
                    null,
                    null,
                    item.alignment === "left" ? 0 : 5,
                  ],
                  paddingRight: [
                    null,
                    null,
                    null,
                    item.alignment === "left" ? 5 : 0,
                  ],
                }}
              >
                <Text
                  sx={{
                    ...styles.subTitle,
                    textAlign: [null, null, null, item.alignment],
                  }}
                >
                  <Zoom>{item.text}</Zoom>
                </Text>
              </Box>
            </Box>
          ))}
        </Box>
      </Container>
    </section>
  );
}

const styles = {
  containerBox: {
    display: "flex",
    pt: [5],
    pb: [5],
  },
  contentBox: {
    width: ["100%"],
  },
  box: {
    width: ["100%"],
    // height: ["260px", "230px", "230px", "300px"],
    display: "inline-block",
    my: [null, null, null, null, 2],
  },
  para: {
    width: ["100%", null, null, "55%"],
    height: [null, null, null, "300px", "360px"],
    transition: "all 0.3s",
    display: "flex",
    alignItems: "center",
  },
  imgContainer: {
    width: ["100%", null, null, "45%"],
    height: "100%",
    display: "flex",
    transition: "all 0.3s",
    justifyContent: "center",
    my: 6,
  },
  img: {
    width: "auto",
    // height: "80%",
    alignSelf: "center",
  },
  subTitle: {
    fontSize: [1, null, null, "14px", 1],
    fontWeight: 400,
    lineHeight: 1.9,
  },
};
