import React from "react";
import { Container } from "theme-ui";
import Carousal from "../../components/reusable/Carousal";

import banner1 from "../../assets/banners/banner1.jpg";
import banner2 from "../../assets/banners/banner2.jpg";
import banner3 from "../../assets/banners/banner3.jpg";
import banner4 from "../../assets/banners/banner4.jpg";

export default function Banners(props) {
  const bannerImages = [banner1, banner2, banner3, banner4];

  return (
    <Container sx={props.styles.banner.container} variant="carousalContainer">
      <Carousal
        //mandatory props
        bannerImages={bannerImages}
        variant="bannerImages"
        //optional props
        autoPlay={true}
        infiniteLoop={true}
        animationHandler="fade"
        showIndicators={true}
        swipeable={false}
        showArrows={false}
        showThumbs={false}
        showStatus={false}
        useKeyboardArrows={false}
        stopOnHover={false}
        dynamicHeight={false}
        autoFocus={false}
        thumbWidth={100}
        interval={5000}
        transitionTime={1000}
      />
    </Container>
  );
}
