import "../styles/App.css";
import "rc-drawer/assets/index.css";
import "react-multi-carousel/lib/styles.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "react-modal-video/css/modal-video.min.css";
import "react-toastify/dist/ReactToastify.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import { ThemeProvider } from "theme-ui";
import theme from "../styles/theme";
import Layout from "./Layout";
import Home from "../sections/home/Home";
import About from "../sections/about/About";
import Contact from "../sections/contact/Contact";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <Home />
        <About />
        <Contact />
      </Layout>
    </ThemeProvider>
  );
}

export default App;
