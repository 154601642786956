/** @jsxImportSource theme-ui */
import React from "react";

export default function TextComponent(props) {
  function handleChange(event) {
    var value = event.target.value;
    if (props.type === "number") {
      var numbers = /^[0-9]+$/;
      // var numbers = /^[+0-9]+$/
      if (value === "" || value.match(numbers)) {
        props.inputChanged(props.placeHolder, value);
      }
    } else {
      props.inputChanged(props.placeHolder, value);
    }
  }

  return (
    <div sx={styles.container}>
      {props.lineType === "singleLine" ? (
        <input
          id={"input" + props.type}
          // type={props.type}
          value={props.value}
          onChange={handleChange}
          placeholder={props.placeHolder}
          sx={{ ...styles.input, ...props.style }}
        />
      ) : (
        <textarea
          id={"input" + props.type}
          value={props.value}
          onChange={handleChange}
          placeholder={props.placeHolder}
          sx={{ ...styles.input, ...props.style }}
        />
      )}
    </div>
  );
}

var styles = {
  container: {
    display: "inline-block",
    // fontSize: 14,
  },
  input: {
    borderRadius: 5,
    border: "1px solid",
    color: "text",
    borderColor: "input_border",
    backgroundColor: "input_background",
    mt: 1,
    height: 35,
    width: "100%",
    resize: "none",
    fontFamily: "input",
  },
};
