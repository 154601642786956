/** @jsxImportSource theme-ui */
import { Container, Image, Box, Grid } from "theme-ui";
import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import { send } from "emailjs-com";

import TextComponent from "../../components/reusable/TextComponent";
import contactUs from "../../assets/contact/contactUs.jpeg";

import SectionHeader from "../../components/reusable/SectionHeader";
import Toast from "../../components/reusable/Toast";
import { contact } from "../../network/apis";
import { Bounce } from "react-reveal";

export default function Form() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [telephone, setTelephone] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmittingForm, setSubmittingForm] = useState(false);

  function isEmailValid() {
    var pattern = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i;
    if (!email.match(pattern)) {
      Toast({ message: "Please enter a valid email id", variant: "warning" });
      document.getElementById("inputemail").focus();
      return false;
    }
    return true;
  }

  function areMandatoryFieldsFilled() {
    var required = [];
    if (!name) required.push("Name");
    if (!email) required.push("Email");
    // if (!telephone) required.push("Telephone");
    if (!message) required.push("message");
    if (required.length) {
      Toast({
        message: "Please fill the required field(s) - " + required.join(", "),
        variant: "warning",
      });
      return false;
    } else if (!required.length && isEmailValid()) {
      return true;
    }
    return false;
  }

  function submitContactForm() {
    if (areMandatoryFieldsFilled()) {
      setSubmittingForm(true);
      var body = "name=" + name + "&email=" + email;
      fetch(contact, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: body,
      })
        .then((response) => {
          return response.json();
        })
        .then((res) => {
          var snackbarMessage = "",
            isSuccess = true;
          if (res.success) {
            snackbarMessage = "Submitted Successfully";
          } else {
            snackbarMessage = "Submission Failed";
            isSuccess = false;
          }
          Toast({ message: snackbarMessage, variant: "success" });
          setSubmittingForm(false);
          setName(isSuccess ? "" : name);
          setEmail(isSuccess ? "" : email);
          setTelephone(isSuccess ? "" : telephone);
          setMessage(isSuccess ? "" : message);
        })
        .catch((err) => {
          Toast({
            message: "Submission Failed. Please try again later.",
            variant: "error",
          });
          setSubmittingForm(false);
          console.log(err);
        });
    }
  }

  function inputChanged(type, value) {
    if (type === "*Name") setName(value);
    else if (type === "*Email") setEmail(value);
    else if (type === "Telephone") setTelephone(value);
    else if (type === "*Message...") setMessage(value);
  }

  const sendMail = (e) => {
    if (areMandatoryFieldsFilled()) {
      setSubmittingForm(true);
      e.preventDefault();
      send(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        {
          name: name,
          email: email,
          telephone: telephone,
          message: message,
        },
        process.env.REACT_APP_USER_ID
      )
        .then((response) => {
          var snackbarMessage = "Submission Failed",
            isSuccess = false;
          if (response.status) {
            snackbarMessage = "Submitted Successfully";
            isSuccess = true;
          }
          Toast({
            message: snackbarMessage,
            variant: isSuccess ? "success" : "error",
          });
          setSubmittingForm(false);
          setName(isSuccess ? "" : name);
          setEmail(isSuccess ? "" : email);
          setTelephone(isSuccess ? "" : telephone);
          setMessage(isSuccess ? "" : message);
        })
        .catch((err) => {
          Toast({
            message: "Submission Failed. Please try again later.",
            variant: "error",
          });
          setSubmittingForm(false);
          console.log(err);
        });
    }
  };

  return (
    <Container sx={styles.container}>
      <Grid gap={2} columns={[1, 1, 1, 1, 2]}>
        <Image src={contactUs} alt="" sx={styles.image} />
        <Box>
          <Bounce>
            <SectionHeader
              slogan="GET IN TOUCH"
              title="We'd love to hear from you"
            />
          </Bounce>
          <Grid gap={[0, 1, 2]} columns={[1, 1, 2]}>
            <TextComponent
              placeHolder="*Name"
              type="text"
              lineType="singleLine"
              value={name}
              inputChanged={inputChanged}
            />
            <TextComponent
              placeHolder="Telephone"
              type="number"
              lineType="singleLine"
              value={telephone}
              inputChanged={inputChanged}
              // style={{width: ["100%", "100%", "100%"]}}
            />
          </Grid>
          <Grid gap={[0, 1, 2]} columns={[1]}>
            <TextComponent
              placeHolder="*Email"
              type="email"
              lineType="singleLine"
              value={email}
              inputChanged={inputChanged}
            />
            <TextComponent
              placeHolder="*Message..."
              type="text"
              lineType="multiLine"
              style={{ height: 80 }}
              value={message}
              inputChanged={inputChanged}
            />
          </Grid>
          {isSubmittingForm ? (
            <button sx={styles.submitButton}>Sending...</button>
          ) : (
            <button sx={styles.submitButton} onClick={sendMail}>
              Send Message
            </button>
          )}
        </Box>
      </Grid>
      <ToastContainer />
    </Container>
  );
}

var styles = {
  container: { display: "flex", pt: 7, justifyContent: "center" },
  image: {
    width: ["100%", null, null, null, "80%"],
    height: ["100%", null, null, null, "80%"],
    alignSelf: "center",
  },
  submitButton: {
    textDecoration: "none",
    textAlign: "center",
    backgroundColor: "accent",
    border: "none",
    width: "101%",
    color: "text_secondary",
    marginTop: 2,
    padding: 2,
    borderRadius: 8,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "warning",
    },
  },
};
