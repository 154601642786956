/** @jsxImportSource theme-ui */
import { Box, Grid, Image } from "@theme-ui/components";
import React from "react";

import { ImOffice } from "react-icons/im";
import { MdMail } from "react-icons/md";
import { RiWhatsappFill } from "react-icons/ri";

export default function CompanyDetails() {
  function getBox(icon, contentHeading, content) {
    var lineSplit = content.split("%");
    return (
      <Box sx={styles.box}>
        {icon}
        <div sx={styles.imageDescription}>{contentHeading}</div>
        <div sx={styles.contentDescription}>
          {lineSplit.map((item, index) => (
            <div key={index}>{item}</div>
          ))}
        </div>
      </Box>
    );
  }
  return (
    <Grid gap={[8, 8, 2]} columns={[1, 1, 3]} sx={styles.grid}>
      {getBox(<MdMail sx={styles.image} />, "Email", "contact@wakeupfarms.com")}
      {getBox(
        <ImOffice sx={styles.image} />,
        "Our Office",
        "Plot No 69, %Checkpost, LB Nagar%Hyderabad, India 500074"
      )}
      {getBox(
        <RiWhatsappFill sx={styles.image} />,
        "WhatsApp",
        "+91 93465 23991"
      )}
    </Grid>
  );
}

var styles = {
  grid: {
    pt: 10,
    pb: 10,
  },
  box: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  image: {
    width: 60,
    height: 60,
    color: "heading_secondary",
  },
  imageDescription: {
    color: "heading_secondary",
    fontWeight: "bold",
    fontFamily: "text",
    textAlign: "center",
    pt: 5,
  },
  contentDescription: {
    color: "text_muted",
    fontWeight: "bold",
    fontFamily: "text",
    pt: 2,
    textAlign: "center",
  },
};
