/** @jsxImportSource theme-ui */
import { Container, Grid } from "theme-ui";
import SectionHeader from "../../components/reusable/SectionHeader";
import Card from "../../components/reusable/Card";

import data from "../../data/highlights.data";

export default function Highlights() {
  return (
    <section id="highlight" sx={{ variant: "section.point" }}>
      <Container>
        <SectionHeader slogan="Slogan" title="title" />

        <Grid sx={styles.grid}>
          {data.map((item) => (
            <Card
              key={item.id}
              src={item.imgSrc}
              alt={item.altText}
              title={item.title}
              text={item.text}
            />
          ))}
        </Grid>
      </Container>
    </section>
  );
}

const styles = {
  grid: {
    pt: [0, null, null, null, null, null, 2],
    px: [5, 6, 0, null, 7, 8, 7],
    gridGap: [
      "40px 0",
      null,
      "45px 30px",
      null,
      "60px 50px",
      "70px 50px",
      null,
      "80px 90px",
    ],
    gridTemplateColumns: ["repeat(1,1fr)", null, "repeat(2,1fr)"],
  },
};
