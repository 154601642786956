const menuItems = [
  {
    path: "home",
    label: "Home",
  },
  {
    path: "about",
    label: "About",
  },
  {
    path: "contact",
    label: "Contact",
  },
];

export default menuItems;
