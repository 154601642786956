/** @jsxImportSource theme-ui */
import React from "react";
import Banners from "./Banners";
import Grafana from "./Grafana";
import KeyPoints from "./KeyPoints";

export default function Home() {
  return (
    <section sx={styles.banner} id="home">
      <Banners styles={styles} />
      {/* <Grafana /> */}
      {/* <KeyPoints /> */}
    </section>
  );
}

const styles = {
  banner: {
    // pt: ["140px", "145px", "155px", "170px", null, null, "180px", "215px"],
    pb: "5px",
    position: "relative",
    zIndex: 2,
    container: {
      minHeight: "inherit",
      display: "flex",
      width: "100%",
      flexDirection: "column",
      justifyContent: "center",
    },
    contentBox: {
      width: ["100%", "90%", "535px", null, "57%", "60%", "68%", "60%"],
      mx: "auto",
      textAlign: "center",
      mb: ["40px", null, null, null, null, 7],
    },
    imageBox: {
      justifyContent: "center",
      textAlign: "center",
      display: "inline-flex",
      mb: [0, null, -6, null, null, "-40px", null, -3],
      img: {
        position: "relative",
        height: [245, "auto"],
      },
    },
  },
};
