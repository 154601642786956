import React from "react";
import AboutUs from "./AboutUs";
import Highlights from "./Highlights";
import Services from "./Services";
import Video from "./Video";
import WorkFlow from "./Workflow";
import Team from "./Team";
import Clients from "./Clients";

export default function About() {
  return (
    <section id="about">
      <AboutUs />
      {/* <Services />
      <Highlights /> */}
      <WorkFlow />
      {/* <Video /> */}
      {/* <Team /> */}
      {/* <Clients /> */}
    </section>
  );
}
